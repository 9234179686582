import React from 'react';
import { Box, Heading, Text, VStack, Container, SimpleGrid, Image } from '@chakra-ui/react';

function Industries() {
  const industries = [
    { name: 'Technology', image: 'https://via.placeholder.com/150' },
    { name: 'Healthcare', image: 'https://via.placeholder.com/150' },
    { name: 'Finance', image: 'https://via.placeholder.com/150' },
    { name: 'Manufacturing', image: 'https://via.placeholder.com/150' },
    { name: 'Retail', image: 'https://via.placeholder.com/150' },
    { name: 'Education', image: 'https://via.placeholder.com/150' },
  ];

  return (
    <Box as="main" py={8}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">Industries We Serve</Heading>

          <Text fontSize="xl" textAlign="center">
            At empowered.guru, we have extensive experience across various industries, allowing us to provide tailored solutions to meet your specific needs.
          </Text>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {industries.map((industry, index) => (
              <Box key={index} borderWidth={1} borderRadius="lg" p={6} textAlign="center">
                <Image src={industry.image} alt={industry.name} mx="auto" mb={4} />
                <Heading as="h3" size="lg">{industry.name}</Heading>
                <Text mt={2}>
                  We provide specialized consulting services tailored to the unique challenges and opportunities in the {industry.name.toLowerCase()} sector.
                </Text>
              </Box>
            ))}
          </SimpleGrid>

          <Text fontSize="lg" textAlign="center">
            No matter your industry, our team of expert consultants is ready to help you navigate challenges and seize opportunities. Contact us to learn how we can empower your success in your specific field.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}

export default Industries;
