import React, { useState } from 'react';
import { Box, Flex, HStack, Text, Link, Spacer, useBreakpointValue, IconButton, Collapse, VStack } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const fontSize = useBreakpointValue({ base: "md", md: "lg", lg: "xl" });
  const spacing = useBreakpointValue({ base: 2, md: 4 });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Box as="header" bg="blue.600" color="white" py={4} boxShadow="md">
      <Flex maxW="container.xl" mx="auto" px={4} flexDirection="row" alignItems="center" justifyContent="space-between">
        <Text fontSize={fontSize} fontWeight="bold">empowered.guru</Text>
        {isMobile ? (
          <IconButton
            onClick={toggle}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            variant="outline"
            aria-label="Toggle Navigation"
          />
        ) : (
          <HStack spacing={spacing} flexWrap="nowrap">
            <Link href="/who-we-serve" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Who We Serve</Link>
            <Link href="/services" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Services</Link>
            <Link href="/industries" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Industries</Link>
            <Link href="/book-online" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Book Online</Link>
            <Link href="/insights" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Insights</Link>
          </HStack>
        )}
      </Flex>
      {isMobile && (
        <Collapse in={isOpen} animateOpacity>
          <VStack spacing={4} mt={4} align="stretch">
            <Link href="/who-we-serve" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Who We Serve</Link>
            <Link href="/services" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Services</Link>
            <Link href="/industries" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Industries</Link>
            <Link href="/book-online" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Book Online</Link>
            <Link href="/insights" fontSize={fontSize} fontWeight="medium" _hover={{ color: "blue.200" }}>Insights</Link>
          </VStack>
        </Collapse>
      )}
    </Box>
  );
}

export default Header;
