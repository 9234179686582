import React from 'react';
import { Box, VStack, Heading, Text, Button, Image, SimpleGrid, Card, CardBody } from '@chakra-ui/react';

function MainContent() {
  return (
    <VStack spacing={16} align="stretch">
      {/* Hero Section */}
      <Box position="relative" height="600px">
        <Image
          src="https://static.wixstatic.com/media/11062b_20125b6ebe434abd96c4d7773634d1dbf000.jpg/v1/fill/w_1585,h_639,al_c,q_85,usm_0.33_1.00_0.00,enc_auto/11062b_20125b6ebe434abd96c4d7773634d1dbf000.jpg"
          alt="Hero background"
          objectFit="cover"
          width="100%"
          height="100%"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
          color="white"
        >
          <Heading as="h1" size="2xl" mb={4}>
            EMPOWER YOUR SUCCESS
          </Heading>
          <Text fontSize="xl" mb={6}>
            Expert consulting that transforms businesses.
          </Text>
          <Button colorScheme="blue" size="lg">
            Consult with Us
          </Button>
        </Box>
      </Box>

      {/* Consulting Services Section */}
      <Box>
        <Heading as="h2" size="xl" mb={8} textAlign="center">
          Consulting Services
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Card>
            <CardBody>
              <Heading as="h3" size="md" mb={4}>
                Strategic Planning
              </Heading>
              <Text>
                With our comprehensive strategic planning services, we help you develop a roadmap for success. We will work with you to achieve your goals, analyze your current situation, and create a plan to help you achieve your objectives. Take your business to the next level.
              </Text>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Heading as="h3" size="md" mb={4}>
                Process Automation
              </Heading>
              <Text>
                Looking to streamline your operations and boost efficiency? Our expert process automation services can help. Let us help you identify areas for improvement and implement solutions that will take your business to the next level.
              </Text>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Heading as="h3" size="md" mb={4}>
                Digital Transformation
              </Heading>
              <Text>
                Technology is a powerful tool to help your business grow and succeed. You can streamline processes and improve efficiency and new customers by leveraging the latest advancements. Let us advise you and help you discover how to use technology as a force multiplier for your business.
              </Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>

      {/* Latest Insights Section */}
      <Box>
        <Heading as="h2" size="xl" mb={8} textAlign="center">
          Latest Insights
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Card>
            <CardBody>
              <Image
                src="https://static.wixstatic.com/media/1173f5_b362710804a54a1d976646f2b314601b~mv2.jpg/v1/fill/w_319,h_179,fp_0.50_0.50,q_90,enc_auto/1173f5_b362710804a54a1d976646f2b314601b~mv2.webp"
                alt="Management Consulting"
                borderRadius="lg"
                mb={4}
              />
              <Heading as="h3" size="md" mb={2}>
                Unleashing the Power of Management Consulting in the Tech Era
              </Heading>
              <Text fontSize="sm" color="gray.500" mb={2}>
                2 min read
              </Text>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Image
                src="https://static.wixstatic.com/media/1173f5_711953d3dcce437f8f9c05d683412581~mv2.png/v1/fill/w_319,h_179,fp_0.50_0.50,q_95,enc_auto/1173f5_711953d3dcce437f8f9c05d683412581~mv2.webp"
                alt="Strategic Technology Consulting"
                borderRadius="lg"
                mb={4}
              />
              <Heading as="h3" size="md" mb={2}>
                Unlocking Business Potential with Strategic Technology Consulting
              </Heading>
              <Text fontSize="sm" color="gray.500" mb={2}>
                2 min read
              </Text>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Image
                src="https://static.wixstatic.com/media/1173f5_43c97f2a69824e27bd544cabd09f1772~mv2.png/v1/fill/w_318,h_179,fp_0.50_0.50,q_95,enc_auto/1173f5_43c97f2a69824e27bd544cabd09f1772~mv2.webp"
                alt="Technology Integration"
                borderRadius="lg"
                mb={4}
              />
              <Heading as="h3" size="md" mb={2}>
                Maximizing Business Success Through Technology Integration
              </Heading>
              <Text fontSize="sm" color="gray.500" mb={2}>
                2 min read
              </Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>
    </VStack>
  );
}

export default MainContent;
