import React from 'react';
import { ChakraProvider, Box, VStack } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import WhoWeServe from './components/WhoWeServe';
import Services from './components/Services';
import Industries from './components/Industries';
import BookOnline from './components/BookOnline';
import Insights from './components/Insights';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <VStack minH="100vh">
          <Header />
          <Box as="main" flex={1} w="full">
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/who-we-serve" element={<WhoWeServe />} />
              <Route path="/services" element={<Services />} />
              <Route path="/industries" element={<Industries />} />
              <Route path="/book-online" element={<BookOnline />} />
              <Route path="/insights" element={<Insights />} />
              {/* Add more routes for other subpages here */}
            </Routes>
          </Box>
          <Footer />
        </VStack>
      </Router>
    </ChakraProvider>
  );
}

export default App;
