import React from 'react';
import { Box, Heading, Text, VStack, Container, SimpleGrid, Button, Link } from '@chakra-ui/react';

function Services() {
  return (
    <Box as="main" py={8}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">Our Services</Heading>

          <Text fontSize="xl" textAlign="center">
            At empowered.guru, we offer a comprehensive suite of consulting services designed to transform your business and drive success.
          </Text>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            <Box borderWidth={1} borderRadius="lg" p={6}>
              <Heading as="h3" size="lg" mb={4}>Strategic Planning</Heading>
              <Text mb={4}>
                With our comprehensive strategic planning services, we help you develop a roadmap for success. We will work with you to achieve your goals, analyze your current situation, and create a plan to help you achieve your objectives.
              </Text>
              <Button as={Link} href="/strategic-planning" colorScheme="blue">Learn More</Button>
            </Box>

            <Box borderWidth={1} borderRadius="lg" p={6}>
              <Heading as="h3" size="lg" mb={4}>Process Automation</Heading>
              <Text mb={4}>
                Looking to streamline your operations and boost efficiency? Our expert process automation services can help. Let us help you identify areas for improvement and implement solutions that will take your business to the next level.
              </Text>
              <Button as={Link} href="/process-automation" colorScheme="blue">Learn More</Button>
            </Box>

            <Box borderWidth={1} borderRadius="lg" p={6}>
              <Heading as="h3" size="lg" mb={4}>Digital Transformation</Heading>
              <Text mb={4}>
                Technology is a powerful tool to help your business grow and succeed. You can streamline processes and improve efficiency by leveraging the latest advancements. Let us advise you and help you discover how to use technology as a force multiplier for your business.
              </Text>
              <Button as={Link} href="/digital-transformation" colorScheme="blue">Learn More</Button>
            </Box>
          </SimpleGrid>

          <Text fontSize="lg" textAlign="center">
            Our team of expert consultants is ready to help you tackle your most pressing business challenges. Contact us today to learn how we can empower your success.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}

export default Services;
