import React from 'react';
import { Box, Heading, Text, VStack, Container, Button, FormControl, FormLabel, Input, Select, Textarea } from '@chakra-ui/react';

function BookOnline() {
  return (
    <Box as="main" py={8}>
      <Container maxW="container.md">
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">Book a Consultation</Heading>

          <Text fontSize="xl" textAlign="center">
            Ready to take your business to the next level? Book a consultation with our expert team today.
          </Text>

          <form>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input placeholder="Your full name" />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input type="email" placeholder="Your email address" />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Phone</FormLabel>
                <Input type="tel" placeholder="Your phone number" />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Service</FormLabel>
                <Select placeholder="Select a service">
                  <option value="strategic-planning">Strategic Planning</option>
                  <option value="process-automation">Process Automation</option>
                  <option value="digital-transformation">Digital Transformation</option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Message</FormLabel>
                <Textarea placeholder="Tell us more about your needs" />
              </FormControl>

              <Button type="submit" colorScheme="blue" size="lg" width="full">
                Book Consultation
              </Button>
            </VStack>
          </form>

          <Text fontSize="sm" textAlign="center">
            By booking a consultation, you agree to our terms of service and privacy policy.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}

export default BookOnline;
