import React from 'react';
import { Box, VStack, HStack, Text, Link, useBreakpointValue } from '@chakra-ui/react';

function Footer() {
  const fontSize = useBreakpointValue({ base: "sm", md: "md" });
  const spacing = useBreakpointValue({ base: 2, md: 4 });

  return (
    <Box as="footer" bg="gray.100" py={8} width="100%">
      <VStack maxW="container.xl" mx="auto" px={4} spacing={4}>
        <Text fontSize={fontSize} textAlign="center">© 2024 empowered.guru, LLC. All rights reserved.</Text>
        <HStack spacing={spacing} flexWrap="wrap" justifyContent="center">
          <Link href="/terms-and-conditions" fontSize={fontSize} fontWeight="medium" color="blue.600" _hover={{ color: "blue.800" }}>Terms and Conditions</Link>
          <Link href="/privacy-policy" fontSize={fontSize} fontWeight="medium" color="blue.600" _hover={{ color: "blue.800" }}>Privacy Policy</Link>
          <Link href="/refund-policy" fontSize={fontSize} fontWeight="medium" color="blue.600" _hover={{ color: "blue.800" }}>Refund Policy</Link>
        </HStack>
      </VStack>
    </Box>
  );
}

export default Footer;
