import React from 'react';
import { Box, Heading, Text, VStack, Container, SimpleGrid, Image, Link } from '@chakra-ui/react';

function Insights() {
  const blogPosts = [
    {
      title: "The Future of AI in Business",
      image: "https://via.placeholder.com/300x200",
      summary: "Explore how artificial intelligence is reshaping industries and creating new opportunities for businesses.",
      link: "/blog/future-of-ai-in-business"
    },
    {
      title: "5 Strategies for Effective Digital Transformation",
      image: "https://via.placeholder.com/300x200",
      summary: "Learn key strategies to successfully navigate your company's digital transformation journey.",
      link: "/blog/digital-transformation-strategies"
    },
    {
      title: "Optimizing Business Processes with Automation",
      image: "https://via.placeholder.com/300x200",
      summary: "Discover how process automation can streamline operations and boost productivity in your organization.",
      link: "/blog/optimizing-business-processes"
    }
  ];

  return (
    <Box as="main" py={8}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">Insights</Heading>

          <Text fontSize="xl" textAlign="center">
            Stay informed with the latest trends, strategies, and insights from our expert consultants.
          </Text>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {blogPosts.map((post, index) => (
              <Box key={index} borderWidth={1} borderRadius="lg" overflow="hidden">
                <Image src={post.image} alt={post.title} />
                <Box p={6}>
                  <Heading as="h3" size="lg" mb={2}>{post.title}</Heading>
                  <Text mb={4}>{post.summary}</Text>
                  <Link href={post.link} color="blue.500" fontWeight="bold">
                    Read More
                  </Link>
                </Box>
              </Box>
            ))}
          </SimpleGrid>

          <Text fontSize="lg" textAlign="center">
            Want more insights? Follow us on social media or subscribe to our newsletter for regular updates and expert advice.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}

export default Insights;
