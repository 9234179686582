import React from 'react';
import { Box, Heading, Text, VStack, Container, Image, SimpleGrid } from '@chakra-ui/react';

function WhoWeServe() {
  return (
    <Box as="main" py={8}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="2xl" textAlign="center">Who We Serve</Heading>

          <Text fontSize="xl" textAlign="center">
            At empowered.guru, we specialize in empowering businesses across various industries to achieve their full potential through strategic consulting and innovative solutions.
          </Text>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {/* Replace these with actual images and content from the original site */}
            <Box>
              <Image src="https://via.placeholder.com/300" alt="Startups" mb={4} />
              <Heading as="h3" size="lg">Startups</Heading>
              <Text>We help startups build strong foundations and scale efficiently.</Text>
            </Box>
            <Box>
              <Image src="https://via.placeholder.com/300" alt="Small Businesses" mb={4} />
              <Heading as="h3" size="lg">Small Businesses</Heading>
              <Text>We empower small businesses to compete and thrive in their markets.</Text>
            </Box>
            <Box>
              <Image src="https://via.placeholder.com/300" alt="Enterprises" mb={4} />
              <Heading as="h3" size="lg">Enterprises</Heading>
              <Text>We assist large enterprises in optimizing operations and driving innovation.</Text>
            </Box>
          </SimpleGrid>

          <Text fontSize="lg">
            Our team of expert consultants brings years of experience across various industries, allowing us to provide tailored solutions that address the unique challenges and opportunities of each client we serve.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}

export default WhoWeServe;
